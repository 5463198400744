/** @jsx jsx */
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import {
  SanityRegion,
  SanityRegionsList,
} from '@bottlebooks/gatsby-theme-cms/src/components';

export default function RegionListTemplate({
  data,
  pageContext,
  location,
  navigate,
}) {
  const locale = data?.site?.siteMetadata?.locales?.[0]?.locale || 'en';
  // Regions may contain nested regions. This means there is a different
  // Behaviour on this route depending on whether there are nested regions
  // e.g. Oregon is 3 levels deep: USA > Oregon > AVAs
  //      NZ is 2 levels deep > NZ > AVAs
  const hasChildRegions = data.allSanityRegion?.nodes.length > 1;
  return hasChildRegions ? (
    // 3+ levels
    <SanityRegionsList {...data.allSanityRegion} locale={locale} />
  ) : (
    // 2 levels
    <SanityRegionWrapper
      {...data.allSanityRegion?.nodes?.[0]}
      locale={locale}
    />
  );
}

function SanityRegionWrapper({ regionHierarchy, locale, ...rest }) {
  // We need to convert the region hierarchy into an array.
  const { countryCode, regionCode, subregionCodes } = regionHierarchy;
  return (
    <SanityRegion
      {...rest}
      regionHierarchy={[countryCode, regionCode, ...subregionCodes].filter(
        Boolean
      )}
      locale={locale}
    />
  );
}
export const pageQuery = graphql`
  query test_RegionListTemplate($countryCode: String!, $regionCode: String!) {
    allSanityRegion(
      filter: {
        regionHierarchy: {
          countryCode: { eq: $countryCode }
          regionCode: { eq: $regionCode }
        }
      }
    ) {
      nodes {
        ...SanityRegion
      }
    }
    site {
      siteMetadata {
        title
        locales {
          locale
        }
      }
    }
  }
`;
